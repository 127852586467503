<template>
    {{ validationMessages }}
    <div class="c-contact-form s-gravity-forms js-contact-form">
        <GlobalLoading v-if="isLoading" />
        <div v-if="showContactForm == false" class="c-contact-form__success s-wp-cms" v-html="formResponse"></div>

        <div v-if="showContactForm">
            <div v-if="!is_newsletter" class="c-contact-form__intro">
                Fields marked with <sup style="color: red">*</sup> are required.
            </div>

            <form v-if="formData?.fields.length > 0" @submit.prevent="submitForm" :data-form="form_id">
                <div class="c-contact-form__inputs">
                    <template v-for="field in formData.fields" :key="field.id">
                        <div
                            v-if="isFieldVisible(field)"
                            :class="`c-contact-form__input-wrapper is-${field.type}`"
                            :style="`grid-column: span ${field.type == 'section' ? 12 : field.layoutGridColumnSpan};`">
                            <template v-if="field.type == 'section'">
                                <div class="c-contact-form__section-heading" v-html="field.label"></div>
                            </template>

                            <template v-if="field.type == 'html'">
                                <div class="c-contact-form__html s-wp-cms" v-html="field.content"></div>
                            </template>

                            <template v-else>
                                <label v-if="field.type != 'hidden'" :for="`input_${field.id}`">
                                    {{ field.label }}
                                    <sup v-if="field.isRequired"> * </sup>
                                </label>

                                <p
                                    v-if="getValidationMessage(field.id)"
                                    class="c-contact-form__validation-message"
                                    v-html="getValidationMessage(field.id)"></p>

                                <div
                                    v-if="field.description"
                                    class="c-contact-form__field-description"
                                    v-html="field.description"></div>

                                <input
                                    v-if="field.type == 'text' || field.type == 'email' || field.type == 'phone'"
                                    :name="`input_${field.id}`"
                                    :id="`input_${field.id}`"
                                    :type="field.type"
                                    :required="field.isRequired"
                                    :placeholder="field.placeholder"
                                    :autocomplete="field.autocompleteAttribute"
                                    :value="getFieldValue(`input_${field.id}`)"
                                    @input="updateFieldValue(`input_${field.id}`, $event.target.value)" />

                                <input
                                    v-if="field.type == 'date'"
                                    :name="`input_${field.id}`"
                                    :id="`input_${field.id}`"
                                    :type="field.type"
                                    :required="field.isRequired"
                                    :value="getFieldValue(`input_${field.id}`)"
                                    @input="updateFieldValue(`input_${field.id}`, $event.target.value)" />

                                <div v-if="field.type == 'time'" class="c-contact-form__time-wrapper">
                                    <div
                                        v-for="(input, index) in field.inputs"
                                        :key="index"
                                        class="c-contact-form__time-atom">
                                        <label :for="`input_${field.id}_${index + 1}`" v-html="input.label"> </label>

                                        <input
                                            v-if="index == 0"
                                            type="number"
                                            :id="`input_${field.id}_${index + 1}`"
                                            :name="`input_${field.id}[]`"
                                            min="1"
                                            max="12"
                                            step="1"
                                            maxlength="2"
                                            placeholder="HH"
                                            @input="updateFieldValue(`input_${input.id}`, $event.target.value)" />

                                        <input
                                            v-if="index == 1"
                                            type="number"
                                            :id="`input_${field.id}_${index + 1}`"
                                            :name="`input_${field.id}[]`"
                                            min="0"
                                            max="59"
                                            step="1"
                                            maxlength="2"
                                            placeholder="MM"
                                            @input="updateFieldValue(`input_${input.id}`, $event.target.value)" />

                                        <select
                                            v-if="index == 2"
                                            :id="`input_${field.id}_${index + 1}`"
                                            :name="`input_${field.id}[]`"
                                            @input="updateFieldValue(`input_${input.id}`, $event.target.value)">
                                            <option value="am">AM</option>
                                            <option value="pm">PM</option>
                                        </select>
                                    </div>
                                </div>

                                <select
                                    v-if="field.type == 'select'"
                                    :name="`input_${field.id}`"
                                    :id="`input_${field.id}`"
                                    :required="field.isRequired"
                                    :value="getFieldValue(`input_${field.id}`)"
                                    @input="updateFieldValue(`input_${field.id}`, $event.target.value)">
                                    <option value="" selected disabled hidden>Please select an option</option>

                                    <option
                                        v-for="(option, index) in field.choices"
                                        :key="index"
                                        :value="option.value"
                                        v-html="option.text"></option>
                                </select>

                                <div
                                    v-if="field.type == 'checkbox'"
                                    v-for="(option, index) in field.choices"
                                    :key="index"
                                    class="c-contact-form__radio-wrapper">
                                    <input
                                        :type="field.type"
                                        :id="`input_${field.id}_${index + 1}`"
                                        :name="`input_${field.inputs[index].id}`"
                                        :value="option.value"
                                        :checked="isCheckboxChecked(`input_${field.inputs[index].id}`, option.value)"
                                        @change="
                                            handleCheckboxChange(`input_${field.inputs[index].id}`, option.value)
                                        " />

                                    <label :for="`input_${field.id}_${index + 1}`" v-html="option.text"> </label>
                                </div>

                                <div v-if="field.type == 'consent'" class="c-contact-form__radio-wrapper">
                                    <input
                                        type="checkbox"
                                        :id="`input_${field.id}_1`"
                                        :name="`input_${field.id}.1`"
                                        :value="`${field.choices[0].value}`"
                                        :checked="isCheckboxChecked(`input_${field.id}.1`, `${field.choices[0].value}`)"
                                        @change="
                                            handleCheckboxChange(`input_${field.id}.1`, `${field.choices[0].value}`)
                                        " />

                                    <label :for="`input_${field.id}_1`" v-html="field.checkboxLabel"> </label>

                                    <input
                                        type="hidden"
                                        :id="`input_${field.id}_2`"
                                        :name="`input_${field.id}.2`"
                                        :value="field.checkboxLabel" />
                                </div>
                                <div
                                    v-if="field.type == 'radio'"
                                    v-for="(option, index) in field.choices"
                                    :key="index"
                                    class="c-contact-form__radio-wrapper">
                                    <input
                                        :type="field.type"
                                        :id="`input_${field.id}_${index}`"
                                        :name="`input_${field.id}`"
                                        :value="option.value"
                                        @input="handleRadioChange(`input_${field.id}`, option.value)" />

                                    <label :for="`input_${field.id}_${index}`" v-html="option.text"> </label>
                                </div>

                                <div v-if="field.type === 'fileupload'">
                                    <input
                                        type="file"
                                        :id="`input_${field.id}`"
                                        :name="`input_${field.id}`"
                                        multiple
                                        @change="handleMultipleFileChange(`input_${field.id}`, $event.target.files)" />
                                </div>

                                <div v-if="field.type == 'textarea'" class="c-contact-form__textarea-wrapper">
                                    <button
                                        v-if="!field.isRequired"
                                        class="c-contact-form__textarea-toggle js-contact-form__textarea-toggle"
                                        type="button"
                                        @click="toggleTextarea($event)">
                                        <i class="fa-solid fa-pencil"></i> Click here to add a message
                                    </button>

                                    <textarea
                                        class="c-contact-form__textarea js-contact-form__textarea"
                                        :class="[{ 'is-active': field.isRequired }]"
                                        :name="`input_${field.id}`"
                                        :id="`input_${field.id}`"
                                        :type="field.type"
                                        :required="field.isRequired"
                                        :placeholder="field.placeholder"
                                        :autocomplete="field.autocompleteAttribute"
                                        :value="getFieldValue(`input_${field.id}`)"
                                        @input="updateFieldValue(`input_${field.id}`, $event.target.value)">
                                    </textarea>
                                </div>
                            </template>
                        </div>
                    </template>

                    <div
                        v-if="formData.button.location = 'inline'"
                        class="c-contact-form__input-wrapper is-submit"
                        :style="`grid-column: span ${formData.button.layoutGridColumnSpan};`">
                        <button class="c-contact-form__submit" type="submit">
                            {{ formData.button.text }}
                        </button>
                    </div>
                </div>

                <button v-if="formData.button.location != 'inline'" class="c-contact-form__submit" type="submit">
                    {{ formData.button.text }}
                </button>
            </form>
        </div>
    </div>
</template>

<script setup>
    const props = defineProps({
        form_id: {
            type: Number,
            required: true,
        },
        location: {
            type: String,
            required: false,
        },
        is_newsletter: {
            type: Boolean,
            required: false,
        },
        type: {
            type: String,
            required: false,
        },
        redirect_url: {
            type: String,
            required: false,
        },
    });

    const {
        isLoading,
        showContactForm,
        formData,
        formResponse,
        errors,
        enteredData,
        isFieldVisible,
        getFieldValue,
        updateFieldValue,
        submitForm,
        handleCheckboxChange,
        isCheckboxChecked,
        handleRadioChange,
        handleFileChange,
        handleMultipleFileChange,
        toggleTextarea,
        getValidationMessage,
    } = useGravityForm(props);
</script>
